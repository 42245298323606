$seperator_blue: #0080ba;
$seperator_grey: #d6d7d1;
$link-color: #0080ba;
$parent_background-color: #fff;
$side-spacing: 3rem;
$gutter-spacing: 2rem;
$data-spacing: 11rem;

.Device {
  margin: 0 !important;

  &__parent {
    padding: 2rem $side-spacing !important;
    border-bottom: 0.15rem solid $seperator_blue;
    background: $parent_background-color;
  }

  &__content {
    padding-left: 1.75rem !important;
  }

  &__content-right {
    margin-top: -2.5rem !important;
  }

  &__options {
    margin: $gutter-spacing 10%;
  }

  &__last-seen {
    color: #6c6b67;
    margin-bottom: $gutter-spacing;
  }

  &__datalist {
    margin-bottom: $gutter-spacing;
  }

  &__data {
    display: flex;
    > span {
      font-weight: bold;
      width: $data-spacing;
      margin-bottom: 0.25rem;
    }
  }

  &__input-fields {
    display: flex;
    align-items: center;
    margin-bottom: $gutter-spacing;
    > span {
      font-weight: bold;
      width: $data-spacing;
    }
  }

  &__screenshot-scheduler {
    display: flex;
    align-items: center;
    margin-bottom: $gutter-spacing;
    > div {
      width: 15rem;
      > span {
        font-weight: bold;
      }
    }
  }

  &__link-text {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
  }

  &__logs-and-screenshots {
    display: flex;
    background: #fff;
    justify-content: space-between;
    border-top: 0.1rem solid $seperator_grey;
    border-bottom: 0.1rem solid $seperator_grey;
    padding: 1rem 10%;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      padding-left: 12% !important;
    }
  }

  &__button-floating {
    position: sticky;
    bottom: 1.5%;
  }

  &__button-save {
    float: right;
    right: 3%;
  }

  &__button-delete {
    position: sticky;
    float: left;
    left: 3%;
  }
}
