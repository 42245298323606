$seperator_blue: #0080bb;
$seperator_grey: #d6d7d1;
$link-color: #0080ba;
$parent_background-color: #fff;
$side-spacing: 3rem;
$gutter-spacing: 2rem;
$data-spacing: 11rem;

.Device1 {
    &_ExportButton {
        position: absolute;
        right: 0;
    }

    &__parent {
        padding: 1rem $side-spacing !important;
        border-bottom: 0.15rem solid $seperator_blue;
        background: $parent_background-color;
    }


    &__content {
        //margin: 4rem 10%;
        //display: inline-block;
        //margin: auto;
        //text-align: center;
        width: 60%;
        min-width: 300px;
        max-width: 400px;
        padding-top: 0.3rem;
        margin: auto;
    }

    &_flexdiv {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0.2rem;
        }

        &_value {
            margin: auto;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0;
        }

        .checkbox {
            margin-right: 10rem;
        }
    }


    &_options {
        max-width: 200px;
        margin-top: 01rem;
        margin-bottom: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0.2rem;
        }


        .checkbox {
            margin-right: 10rem;
            margin: auto;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0;
        }
    }

    &_button {
        width: 40%;
        margin: auto;
        margin-top: 1.5rem;
        min-width: 140px;

    }

    &__last-seen {
        text-align: left !important;
        font-family: Montserrat;
        font-size: 14px;
        padding-top: 5px;
        margin-left: 0.4rem;
    }


    &_graphContainer {
        width: 90%;
        margin-top: 3rem;
        margin-left: 5%;
        margin-bottom: 5rem;

        &_button-left {
            border: 10px 0 0 10px !important;
        }

        &_button--selected {
            color: $seperator_blue
        }
    }
}

.Switches {

    .switch-toggle {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 15px;
        //float: left;
        background: transparent;
        width: 60%;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        min-width: 360px;

        &_right {
            border-radius: 10px 0 0 10px;
        }

        &_left {
            border-radius: 0 10px 10px 0;
            
        }
    }

    .switch-toggle input {
        // position: absolute;
        display: inline-block;
        position: absolute;
        //top: -3em;
        opacity: 0;
        margin: auto;
        margin-bottom: 5rem;
    }

    .switch-toggle input + label {
        
        padding: 7px;
        color: $seperator_blue;
        cursor: pointer;
        border: 2px solid $seperator_blue;
        background: #fff;
    }

    .switch-toggle input:checked + label {
        background: $seperator_blue;
        color: #fff;
    }
}

