$link-color: #0080ba;


.EditDevice {

    &_content {
        text-align: center;
        // width: 50%;
        min-width: 200px;
        max-width: 400px;
        padding-top: 3rem;
        margin: auto;
    }


    &_flexdiv {
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_text {
            align-self: flex-start;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 18px;
            padding-top: 9.5px;
            margin-left: 1rem;
        }

        .input {
            min-width: 190px;
            max-width: 250px;
            border-radius: 5px;
            border: 1px solid #0080bb;
            height: 35px;
            align-self: flex-end;
            margin-right: 1rem;
        }


        &_dropdown {
            width: 190px !important;
            border-radius: 5px;
            border: 1px solid #0080bb !important;
            height: 35px;
            align-self: flex-end;
            margin-right: 1rem;
        }
    }

    &_selectCrop {
        margin-top: 0.7rem;
        display: flex;
        flex-direction: row;
        align-self: flex-start !important;

        &_button {
            flex: 1;
            align-self: flex-start !important;
            margin-left: 0 !important;
            margin-right: 1rem !important;
        }

        &_text {
            padding-top: 6px;
            margin-left: 0.4rem;
        }
    }

    &_blockdiv {
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        text-align: left;

        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 20px;
        }

        .input {
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            min-width: 200px;
            border-radius: 5px;
            border: 1px solid #0080bb;
        }
    }


    &_sensorsflexdiv {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_text {
            align-self: flex-start;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 16px;
            padding-top: 9.5px;
            margin-left: 1rem;
        }




        &_checkbox {
            margin-top: 0.4rem;
            margin-right: 150px;
        }
    }




    &_buttonContainer {
        color: #000000;
        font-family: "Montserrat";
        width: 30%;
        max-width: 200px;
        border-radius: 10px;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }


    &_button {
        color: #000000;
        font-family: "Montserrat";
        max-width: 200px;
        border-radius: 10px;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}
