

$seperator_grey: #d6d7d1;
$child_background-color: #fff;
$side-spacing: 3rem;
$seperator_blue: #0080bb;

.DevicesManagerList {
    // margin: 0 !important;
    .ui.relaxed.list {
        margin: 0;
    }

    &_plot {
        display: flex;
        position: relative;
        align-items: center;
        border-bottom: 0.15rem solid $seperator_grey;
        background: $child_background-color;
        padding: 1.5rem $side-spacing !important;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            padding-left: 4rem !important;
        }

        &_content {
            padding-left: 1.75rem !important;
        }

        &_header {
            margin-top: 10px;
            font-weight: 800 !important;
            font-size: 20px !important;
            margin-bottom: 5px !important;
            color: $seperator_blue;
        }

        &_description {
            font-weight: 100;
            font-size: 14px;
        }
    }

    &_device {
        display: flex;
        position: relative;
        align-items: center;
        border-bottom: 0.15rem solid $seperator_grey;
        background: $child_background-color;
        padding: 1.5rem $side-spacing !important;
        cursor: pointer;
        transition: 0.5s;

        &_content {
            padding-left: 1.75rem !important;
        }

        &_header {
            margin-top: 10px;
            font-weight: 800 !important;
            font-size: 20px !important;
            margin-bottom: 5px !important;
            flex-direction: column !important;
        }

        &_HeaderContainer {
            flex-direction: row;
        }

        &_EditButton {
            height: 28px !important;
            width: 70px !important;
            font-size: 12px !important;
            align-content: center !important;
        }

        &_description {
            font-weight: 100;
            font-size: 14px;
        }
    }

    &_Editbutton {
        margin-left: 10px !important;
    }
}
/*
.GreenhousesList {
  margin: 0 !important;

  .ui.relaxed.list {
    margin: 0;
  }

  &__parent {
    padding: 1.25rem 1rem !important;
    border-bottom: 0.15rem solid $seperator_grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__edit-name {
    margin-right: 1rem !important;
  }

  &__greenhouse {
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 0.15rem solid $seperator_grey;
    background: $child_background-color;
    padding: 1.5rem $side-spacing !important;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      padding-left: 4rem !important;
    }
  }

  &__content {
    padding-left: 1.75rem !important;
  }

  &__content-right {
    position: absolute;
    right: 3rem;
  }
}

    */
