$error_color: #c84044;
$warning_color: #fbbd08;
$success_color: #21ba45;
$info_color: #00b5ad;

.Toast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  color: #fff;
  height: 6rem;

  > div {
    display: flex;
    align-items: center;
  }

  &__title {
    text-transform: uppercase;
  }

  &__icon {
    font-size: 3rem !important;
    margin-right: 2rem !important;
  }

  // STATES:

  &__error {
    background: $error_color;
  }

  &__warning {
    background: $warning_color;
  }

  &__success {
    background: $success_color;
  }

  &__info,
  &__ {
    background: $info_color;
  }
}
