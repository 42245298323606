$top-bar_background-color: #0080ba;
$top-bar_font-color: #fff;

.Layout {



    &__icon-settings {
        color: $top-bar_font-color;

        &:hover {
            color: $top-bar_font-color;
        }
    }

    &__top-bar {
        font-family: "Montserrat";

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1rem;
        background: $top-bar_background-color;
        color: $top-bar_font-color;

        > span {
            font-size: 1.5rem;
            font-weight: bold;
        }

        i {
            cursor: pointer;
        }
    }
}
