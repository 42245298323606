$warning-background_color: #c84044;
$link-color: #0080ba;


.Settings {
    padding-bottom: 250px;

    &__content {
        //margin: 4rem 10%;
        //display: inline-block;
        //margin: auto;
        //margin-top: 4rem;
        text-align: center;
    }

    &_form {
        width: 50%;
        margin: auto;
        max-width: 200px;
        margin-top: 4rem;
        margin-bottom: 5rem;
    }



    &__button {
        font-family: Montserrat;
        text-transform: uppercase;
        border-radius: 15px !important;
        margin-bottom: 1rem;
    }

    &__text {
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }


    &__link-text {
        font-style: italic;
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }

    &__select-language {
        color: $link-color;
        font-family: Montserrat;
        font-size: 17px;
    }

    &__languages {
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        margin-top: 3rem;
        width: 17rem;
    }

    &__flag {
        background-color: #ffffff;
        cursor: pointer;
        opacity: 0.1;
        transition: 0.25s;
        color: $link-color;

        > img {
            width: 5rem;
            height: 3rem;
        }

        &:hover {
            opacity: 0.5;
        }

        &--selected {
            opacity: 1;
        }
    }
}

