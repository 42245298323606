$link-color: #0080ba;


.Verification {
    padding-bottom: 250px;

    &__content {
        //margin: 4rem 10%;
        //display: inline-block;
        //margin: auto;
        // margin-top: 4rem;
        text-align: center;
    }

    &_form {
        width: 50%;
        margin: auto;
        max-width: 320px;
        margin-top: 4rem;
        margin-bottom: 5rem;

        .input {
            margin: auto;
            margin-bottom: 0.75rem;
            max-width: 40px;
            /* Style for "Rectangle" */
            border-radius: 5px;
            border: 1px solid #0080bb;
        }
    }

    &__Input {
        margin: auto;
        width: 200px;
        display: flex;
        margin-bottom: 2rem;
    }

    &__button {
        font-family: Montserrat;
        font-size: 20px;
        text-transform: uppercase;
        border-radius: 10px;
        margin: auto;
        margin-bottom: 1rem;
        max-width: 200px;
    }

    &__text {
        font-family: Montserrat;
        font-size: 24px;
        margin-bottom: 1rem;
        color: #0080bb;
        font-weight: 600;
    }

    &__description {
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 2rem;
    }


    &__link-text {
        font-style: italic;
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }
}

