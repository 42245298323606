$link-color: #0080ba;

.Login {
    padding-bottom: 250px;

    &__content {
        //margin: 4rem 10%;
        //display: inline-block;
        //margin: auto;
        text-align: center;
    }

    &__logo {
        width: 40% !important;
        margin-top: 5rem !important;
        min-width: 12.5rem !important;
        max-width: 100px !important;
        margin-bottom: 2rem !important;
    }

    &_form {
        margin-bottom: 3rem;

        .input {
            margin: auto;
            margin-bottom: 0.75rem;
            width: 60%;
            max-width: 250px;
            /* Style for "Rectangle" */
            border-radius: 5px;
            border: 1px solid #0080bb;
        }
    }

    &__button {
        color: #000000;
        font-family: "Montserrat - Semi Bold";
        text-transform: uppercase;
        width: 50%;
        max-width: 120px;
        border-radius: 10px;
        margin: auto;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    &__text {
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }


    &__link-text {
        font-style: italic;
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }

    &__select-language {
        color: $link-color;
        font-family: Montserrat;
        font-size: 17px;
    }

    &__languages {
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        width: 17rem;
    }

    &__flag {
        background-color: #ffffff;
        cursor: pointer;
        opacity: 0.1;
        transition: 0.25s;
        color: $link-color;

        > img {
            width: 5rem;
            height: 3rem;
        }

        &:hover {
            opacity: 0.5;
        }

        &--selected {
            opacity: 1;
        }
    }
}


.social {

    &__container {
        padding: 25px 50px;
        margin: auto;
        justify-content: space-between;
    }

    &__text {
        font-family: Montserrat;
        font-size: 17px;
        margin: 0 0.4rem 0.5rem 0;
    }
}

a.social {
    margin: 0 0.4rem 0.5rem 0;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}