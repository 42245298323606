$_blue: #0080bb;
.minus {
    margin-top: .2rem !important;
}

.plus {
    margin-top: .2rem !important;
    margin-left: .2rem !important;
}

.whiteIcon {
    color: #ffffff
}

.number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {
    //margin-bottom: 3rem;
    height: 2rem;
    display: flex;
    justify-content: center;
}

.number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    text-align: center;
}

.number-input.number-input {
    border: 1px solid #ced4da;
    width: 8rem;
    border-radius: 8px;
    background-color: $_blue;
}

.number-input.number-input button {
    width: 25%;
    height: 1rem;
    border: 0;
}

.number-input.number-input button.minus {
    padding-left: 10px;
}

.number-input.number-input button:before,
.number-input.number-input button:after {
    width: .7rem;
    background-color: #fff;
}

.number-input.number-input input[type=number] {
    max-width: 50%;
    padding: .5rem;
    border: 2px solid $_blue;
    border-width: 2px 1px;
    height: 100%;
    color: $_blue;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 15px;
}

.MoreOptions {
    &_timeTags {
        width: 60px;
        height: 30px;
        margin: 4px;
    }
    &__content {
        width: 50%;
        min-width: 200px;
        max-width: 400px;
        padding-top: 2rem;
        margin: auto;
    }
    &_flexdiv {
        margin-top: 0.2rem;
        margin-bottom: 0.4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0.2rem;
        }
        &_numberInput {
            margin: auto;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0;
            float: right;
            width: 120px;
        }
        &_value {
            margin: auto;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 15px;
            padding-top: 3px;
            margin-right: 0;
        }
        .checkbox {
            margin-right: 1rem;
        }
    }
}