.ReadingsChart {
  &__title {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-bottom: -0.75rem !important;

    button {
      margin-left: 1rem !important;
    }
  }
  &__responsive-container {
    margin-bottom: 2rem;
    height: 12.5rem !important;
  }
}
