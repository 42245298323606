 $seperator_blue: #0080ba;
 $seperator_grey: #d6d7d1;
 $link-color: #0080bb;
 $parent_background-color: #fff;
 $side-spacing: 3rem;
 $gutter-spacing: 2rem;
 $data-spacing: 11rem;

.User {
    margin: auto;

    &_heading {
        color: $link-color;
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}


.Greenhouses {
    &_button-container {
        margin-top: 3rem;
        display: flex;
        justify-Content: center;
        align-Items: center;
        flex-direction: column;
    }

    &_button-add {
        display: flex;
        flex-direction: column;
        margin: auto;
        align-self: center;
        width: 80px;
        height: 80px;
        border-radius: 50% !important;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    &_button-text {
        color: $link-color;
        font-size: 16px;
        font-weight: 600;
    }

    &_button-icon {
        margin-top: 20px !important;
        align-self: center !important;
        font-weight: 100 !important;
        font-size: 50px !important;
        color: #fff !important;
        //width: 50px;
        //height: 50px;
    }
}

    // .user {
    //   margin: 0 !important;
    //   &__parent {
    //     padding: 2rem $side-spacing !important;
    //     border-bottom: 0.15rem solid $seperator_blue;
    //     background: $parent_background-color;
    //   }
    //   &__content {
    //     padding-left: 1.75rem !important;
    //   }
    //   &__content-right {
    //     margin-top: -2.5rem !important;
    //   }
    //   &__options {
    //     margin: $gutter-spacing 10%;
    //   }
    //   &__last-seen {
    //     color: #6c6b67;
    //     margin-bottom: $gutter-spacing;
    //   }
    //   &__datalist {
    //     margin-bottom: $gutter-spacing;
    //   }
    //   &__data {
    //     display: flex;
    //     > span {
    //       font-weight: bold;
    //       width: $data-spacing;
    //       margin-bottom: 0.25rem;
    //     }
    //   }
    //   &__input-fields {
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: $gutter-spacing;
    //     > span {
    //       font-weight: bold;
    //       width: $data-spacing;
    //     }
    //   }
    //   &__screenshot-scheduler {
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: $gutter-spacing;
    //     > div {
    //       width: 15rem;
    //       > span {
    //         font-weight: bold;
    //       }
    //     }
    //   }
    //   &__link-text {
    //     text-decoration: underline;
    //     color: $link-color;
    //     cursor: pointer;
    //   }
    //   &__logs-and-screenshots {
    //     display: flex;
    //     background: #fff;
    //     justify-content: space-between;
    //     border-top: 0.1rem solid $seperator_grey;
    //     border-bottom: 0.1rem solid $seperator_grey;
    //     padding: 1rem 10%;
    //     cursor: pointer;
    //     transition: 0.5s;
    //     &:hover {
    //       padding-left: 12% !important;
    //     }
    //   }
    //   &__button-save {
    //     position: sticky;
    //     float: right;
    //     right: 3%;
    //     bottom: 4%;
    //   }
    // }
