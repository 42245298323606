$seperator_blue: #0080ba;

.selectCropModal {
    width: 300px !important;
    height: 300px !important;
    background-color: #ffffff !important;
    border-color: $seperator_blue !important;
    border-width: 2px !important;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -150px !important;
    margin-left: -150px !important;


    &_Header {
        color: $seperator_blue !important;
    }

    &_flexDiv {
        display: flex !important;
        flex-direction: row !important;
        align-content: space-around !important;
    }

    &_checkBox {
        flex: 1;
        margin-top: 0 !important;
        margin-left: 2rem;
       
    }

    &_Button {
        background-color: $seperator_blue !important;
        width: 80px;
        border-radius: 10px;
        position: fixed !important;
        left: 110px;
        bottom: 10px;
    }

    &_Button_Text {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}


.ui.checkbox label {
    color: $seperator_blue !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}



.ui.dimmer {
    background-color: rgba(0,0,0, 0.3) !important;
}



.ui.modal {
    border-color: $seperator_blue !important;
    border-width: 2px !important;
    border-radius: 10px !important;


    .header {
        margin: auto !important;
        text-align: center;
        border-bottom: 0 !important;
    }
}

label {
    margin-bottom: 0;
}