$seperator_blue: #0080ba;
$parent_background-color: #fff;
$side-spacing: 3rem;
$online_green: #6ca340;
$offline_red: red;

.DevicesList {
    margin: 0 !important;

    &_parent {
        display: flex !important;
        align-items: center !important;
        padding: 2rem $side-spacing !important;
        border-bottom: 0.15rem solid $seperator_blue !important;
        background: $parent_background-color !important;
        color: $seperator_blue !important;
    }

    &_content {
        padding-left: 1.75rem !important;
    }

    &_device {
        display: flex;
        border-bottom: 0.15rem solid $seperator_blue !important;
        padding: 1.5rem 0 !important;
        margin: 0 $side-spacing !important;
        cursor: pointer !important;
        transition: 0.5s !important;

        &:hover {
            padding-left: 1rem !important;
        }

        &_content-right {
            margin-top: -3rem !important;
            margin-right: 0;
            align-self: flex-end;
        }
    }



    &_header {
        font-weight: 600 !important;
        font-size: 18px !important;
        margin-bottom: 5px !important;
        color: $seperator_blue !important;
    }

    &_description {
        font-weight: 100 !important;
        font-size: 14px !important;
        color: $seperator_blue !important;
    }
}


