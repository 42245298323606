$link-color: #0080ba;


.Signup {
    &__content {
        //margin: 4rem 10%;
        //display: inline-block;
        //margin: auto;
        text-align: center;
        width: 60%;
        min-width: 400px;
        max-width: 600px;
        padding-top: 3rem;
        margin: auto;
    }

    &_form {
    }

    &_flexdiv {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 20px;
            padding-top: 9.5px;
            margin-right: 0.4rem;
        }

        .input {
            margin-right: 0;
            min-width: 200px;
            max-width: 250px;
            border-radius: 5px;
            border: 1px solid #0080bb;
            height: 35px;
        }
    }


    &_blockdiv {
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        text-align: left;

        &_text {
            margin-left: 0.4rem;
            color: #0080bb;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 20px;
        }

        .input {
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            min-width: 200px;
            border-radius: 5px;
            border: 1px solid #0080bb;
        }
    }


    &__buttonContainer {
        color: #000000;
        font-family: "Montserrat";
        width: 30%;
        max-width: 200px;
        border-radius: 10px;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }


    &__button {
        color: #000000;
        font-family: "Montserrat";
        max-width: 200px;
        border-radius: 10px;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }


    &__optional {
        font-weight: 100;
        color: #000;
        text-align: left;
        font-family: Montserrat;
        font-size: 14px;
       // margin-bottom: 0.5rem;
    }


    &__text {
        text-align: center;
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }


    &__link-text {
        font-style: italic;
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }
}

