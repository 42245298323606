

body {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0px !important;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    min-width: 320px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff !important;
    background-image: url(./static/background.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: rgba(80,80,80,.87) !important;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
